import React from 'react';
import { useState } from 'react';
import './index.css';
import paisebnaotext from '../../assets/images/paisebnaotext.png'
import demoWebImg from "../../assets/images/demoimg.png"
import playstorebtn from "../../assets/images/GooglePlay.png"
import watchVideo from "../../assets/images/player.png"
import refer from "../../assets/images/earnmoney.png"
import earn from "../../assets/images/earned.png"
import GetStartedNow from "../../assets/images/GetStartedNow.png"
import MrManoj from "../../assets/images/Mrmanoj.jpg"
import { useNavigate } from 'react-router-dom';
import paisebanoQr from "../../assets/images/paisebnaoQr.png"

import ReactPlayer from 'react-player';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  
  const handleRedirectRegister = () => {
    navigate('/register');
};

  const handleRedirect = () => {
    navigate('/signup');
};
const toggleMenu = () => {
  setIsDropdownOpen(!isDropdownOpen);
};
  return (
    <div className="App">


<header className="header">
        <img src={paisebnaotext} alt="paisebanotextlogo" className="smalltextlogo ml-7 mr-2" />
        <nav className="nav">
          <a onClick={handleRedirectRegister}>Register</a>
          <a onClick={handleRedirect} href="#login">Login</a>
          <button className="download-btn">Download App</button>
        </nav>
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776; 
        </div>
      </header>
      <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
      <a onClick={handleRedirectRegister}>Register</a>
        <a onClick={handleRedirect} href="#login">Login</a>
        <button className="download-btn">Download App</button>
      </div>


      <section className="hero">
        <h1>Watch Videos, Earn Money</h1>
        <p>Discover a new way to earn while you relax. Watch engaging videos and get paid instantly. Join our community today and turn your screen time into cash. It's fun, easy, and rewarding!</p>
        <div className="video-container">
        <div className="video-player">
    <ReactPlayer
        url="https://www.youtube.com/watch?v=u2NuUWuwPCM&ab_channel=ParamountPictures"
        controls={true}
        playbackRate={1}
        width="100%"
        height="100%"
    />
</div>
        </div>
        <br/>
        <img onClick={handleRedirect} className='getStartedNow' src={GetStartedNow} />
      </section>
      <section className="how-to-earn">
        <h2>How to Earn?</h2>
        <div className="earn-options">
          <div className="option">
            <img className='refernearn' src={watchVideo} alt="Watch Videos" />
            <h3>Watch Videos</h3>
            <p>Commercial ads, movies, and TV shows online. It's the perfect way to earn money.</p>
          </div>
          <div className="option">
            <img className='refernearn' src={refer}  alt="Refer Your Friends" />
            <h3>Refer Your Friends</h3>
            <p>We’ll confirm as soon as your friend joins. You get ₹50 and your friend gets ₹250.</p>
          </div>
          <div className="option">
            <img className='refernearn' src={earn}  alt="Get Paid" />
            <h3>Get Paid</h3>
            <p>You can transfer it to your Paytm, Phonepe, and more options.</p>
          </div>
        </div>
      </section>
      <section className="about-us">
  <h2>About Us</h2>
  <div className="about-content">
    <img className='Mrmanoj' src={MrManoj} alt="Mr Manoj Hunidwal" />
    <div className='details-container'>
      <p>
        All the lorem ipsum generators on the Internet tend to repeated predefined chunks as necessary, making this the first true value generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful.
      </p>
      <div className="contact-info mrmanojsection">
        <img src={paisebanoQr} alt="QR Code" className="qr-code mb-20" />
        <div className="contact-details">
          <span className='mrmaojfontcolour'>Mr Manoj Hunidwal</span>
          <p>Email: paisebnao@gmail.com</p>
          <p>Phone: (+91) 8580584898</p>
        </div>
      </div>
    </div>
  </div>
</section>
      <section className="every-minute-counts">
        <div>
          <h2>Every minute counts</h2>
        <p>With PaiseBnao, you can earn rewards just by watching videos. It's simple, fun, and rewarding.</p>
        

          <img className="google-play-btn" src={playstorebtn} />
        
        </div>
        <img className='demoWebImg' src={demoWebImg} />
      </section>


      <section className="contact-us">
        <h2>Contact Us</h2>
        <form className="contact-form">
          <input type="text" name="name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email Address" />
          <textarea name="message" placeholder="Your Message"></textarea>
          <button type="submit">Send</button>
        </form>
        <div className="contact-details">
          <p>PaiseBnao pvt.ltd Gurugaon</p>
          <p>Phone: +91 8989898989</p>
          <p>Email: paisebanoo@info.com</p>
         <div className='maxwidth567'>

         <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224346.1409021248!2d76.85626542773614!3d28.42318791829747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18a89d2198fb%3A0x8a0932e528a6f3ad!2sGurugram%2C%20Haryana%2C%20India!5e0!3m2!1sen!2sus!4v1622995223932!5m2!1sen!2sus"
        title="Gurgaon Map"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>

         </div>
        </div>
      </section>


      
      <footer className="footer">
  <div className="footer-container">
    <div className="logofootersection">
      <img src={paisebnaotext} alt="paisebanotextlogo" className="smalltextlogo" />
      <p>Lorem ipsum dolor sit amet consectetur. Odio amet natoque tortor convallis. Bibendum sapien suspendisse ipsum urna malesuada elit. Bibendum vitae nibh scelerisque sed aliquam ullamcorper est.</p>
    </div>

    <div className="footer-links">
      <h5>Quick Links</h5>
      <span><a href="#terms">Terms and conditions</a></span>
      <span><a href="#help">Help page</a></span>
    </div>

    <div className="footer-content">
      <p>&copy; 2024 PaiseBnao</p>
      <p>
        Maintained by <strong><a href="https://www.yarsicorp.com" target="_blank" rel="noopener noreferrer">Yarsicorp Tech Services</a></strong>, your trusted partner in technology solutions.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
}

function handlePlayVideo() {
  // Logic to handle video playing
  alert('Playing Video!');
}

export default App;
