import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleAuth = () => {
  const navigate = useNavigate();
  
  const handleSuccess = (response) => {
    console.log('Login Success:', response);
    const { credential } = response; 
    localStorage.setItem('google_access_token', credential); 
    
    navigate('/dashboard'); 
  };

  const handleFailure = (error) => {
    console.log('Login Failed:', error);
    
    navigate('/login'); 
  };

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div className="google-login-container">
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleFailure}
          buttonText="Continue with Google"
          render={renderProps => (
            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google-login-btn">
              Continue with Google
            </button>
          )}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
