import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import backicon from '../../../assets/images/user.png'; 
import fullnameicon from '../../../assets/images/user.png'; 
import mobileicon from '../../../assets/images/user.png'; 
import emailicon from '../../../assets/images/user.png'; 
import lockicon from '../../../assets/images/user.png'; 
import referralicon from '../../../assets/images/user.png'; 
import eyeon from '../../../assets/images/user.png'; 
import eyeoff from '../../../assets/images/user.png'; 
import Container from '../../container';

import { registerUser } from '../../../api/api';

const RegisterScreen = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [validateEmail, setValidateEmail] = useState(false);
  
  
  const navigate = useNavigate();

  const handleRegister = async () => {
    const userData = {
      phone_number: phoneNumber,
      email: email,
      first_name: firstName,
      // last_name: lastName,
      password: password,
      referral_id: referralCode
    };


    try {
      const response = await registerUser(userData);
      console.log(response?.access_token, "res of register api");

      if (response?.access_token) {
        localStorage.setItem('access_token', response?.access_token);
        alert(localStorage.getItem("access_token"))
        setMessage('Registration successful!');
        navigate('/dashboard');
      } else {
        setMessage('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setMessage('Registration failed. Please try again.');
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidateEmail(emailRegex.test(value));
  };
  const handleNameChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z\s]/g, '');
    setFirstName(cleanedValue);
  };

  const handlePhoneNoChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue && !/^[6-9]/.test(cleanedValue)) {
      setIsValid(false);
      return;
    }
    if (cleanedValue.length <= 10) {
      setPhoneNumber(cleanedValue);
    }
    const phoneRegex = /^[6-9][0-9]{9}$/; 
    setIsValid(phoneRegex.test(cleanedValue));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    handleRegister();
  };

  return (
    <Container >
    <div>
      <Header />
      <h2 className="title">PaiseBnao</h2>
      <h3 className="subtitle">Register</h3>
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-wrapper mb-20">
          <img src={fullnameicon} alt="Full Name Icon" className="icon-size icon-inside-input lock-icon" />
          <input
        type="text"
        placeholder="Enter your full name"
        className="input-field"
        value={firstName}
        onChange={handleNameChange}
        required
        maxLength={20}
      />
        </div>
        <div className="input-wrapper mb-20">
          <img src={mobileicon} alt="Mobile Icon" className="icon-size icon-inside-input lock-icon" />
          
      <input
        type="text"
        placeholder="Enter your mobile number"
        className={`input-field ${isValid ? '' : 'input-invalid'}`}
        value={phoneNumber}
        onChange={handlePhoneNoChange}
        required
        maxLength={10} 
      />
      {!isValid && <span className="error-message">Invalid phone number</span>}
    
        </div>
        <div className="input-wrapper mb-20">
          <img src={emailicon} alt="Email Icon" className="icon-size icon-inside-input lock-icon" />
          <input
        type="email"
        placeholder="Enter your email"
        className={`input-field ${validateEmail ? '' : 'input-invalid'}`}
        value={email}
        onChange={handleEmailChange}
        required
      />
      {!validateEmail && <span className="error-message">Invalid email address</span>}
        </div>
        <div className="input-wrapper mb-10 ">
          <img src={lockicon} alt="Password Icon" className="icon-size icon-inside-input lock-icon" />
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Enter your password"
            className="input-field mb-17"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <img
            src={passwordVisible ? eyeon : eyeoff}
            alt="Toggle Visibility"
            className="visibility-icon icon-size icon-inside-input"
            onClick={togglePasswordVisibility}
          />
        </div>
        {password.length <= 8  ? 
        <small className="text mb-20">Must contain 8 characters</small> : <small className="text mb-20"></small>}
        <div className="input-wrapper mb-20">
          <img src={lockicon} alt="Password Icon" className="icon-size icon-inside-input lock-icon" />
          <input
            type={confirmPasswordVisible ? "text" : "password"}
            placeholder="Confirm your password"
            className="input-field"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <img
            src={confirmPasswordVisible ? eyeon : eyeoff}
            alt="Toggle Visibility"
            className="visibility-icon icon-size icon-inside-input"
            onClick={toggleConfirmPasswordVisibility}
          />
        </div>
        <div className="input-wrapper mb-20">
          <img src={referralicon} alt="Referral Icon" className="icon-size icon-inside-input lock-icon" />
          <input
            type="text"
            placeholder="Enter Referral Code"
            className="input-field"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
        </div>
        <button type="submit" className="register-button">Continue</button>
      </form>
      {message && <p className="text">{message}</p>}
      <p className="text">
        By continuing, you agree to <a href="/termsandcondition" className="red-link">Terms & Conditions</a> and <a href="/privacy" className="red-link">Privacy Policy</a>
      </p>
      <div className="flex-row mt-10">
        <img src="./icons/safelogo.png" alt="Safe Logo" className="smalltextlogo" />
        <p className="fontstyle ml-10">100% Safe & Secure</p>
      </div>
    </div>
    </Container>
  );
};

export default RegisterScreen;
