// src/components/Login.js
import React from 'react';

const Login = () => {
  const handleLogin = () => {
    // Simulate login and store auth token
    localStorage.setItem('authToken', 'exampleToken');
  };

  return (
    <div>
      <h2>Login Page</h2>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
