import React, { useState } from 'react';
import '../Login/LoginScreen.css';
import Header from '../common/header';
import lock from '../../../assets/images/lock.png';
import { useNavigate } from 'react-router-dom';


const VerifyAccount = () => {
    const [code, setCode] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,4}$/.test(value)) {
      setCode(value);
      setValidationMessage('');
    } else {
      setValidationMessage('max Otp length is  4-digit .');
    }
  };


    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate('/update-password');
      };

    return (
        <div className="verify-account-container">
            <div className='flex-row mt-10'>
            <Header />
            </div>
           
            <h2 className="verify-title">Verify Account</h2>
            <p className="verify-instructions">
                Code has been sent to <span className="email-highlight">johndoe@gmail.com</span>.<br />
                Enter the code to verify your account.
            </p>
            <div className="input-container">
                <img src={lock} alt="Lock Icon" className="icon-size" />
                <input
                type="text"
                placeholder="4 digit code"
                className="input-field"
                value={code}
                onChange={handleChange}
            />
            
            </div>
            {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
            <p className="resend-text">
                Didn’t Receive Code? <a href="/resend" className=" colournone font-500 underline">Resend Code</a>
            </p>
            <p className="resend-text">Resend code in 00:59</p>
            <button onClick={handleNavigation} className="verify-button">Verify Account</button>
        </div>
    );
};

export default VerifyAccount;
