import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import { useState , useEffect} from 'react';
import profileImage from  '../../../assets/images/userimg.png'; 
import editProfileIcon from '../../../assets/images/eye-off.png'; 
import referIcon from '../../../assets/images/eye-off.png'; 
import withdrawIcon from '../../../assets/images/eye-off.png'; 
import helpIcon from '../../../assets/images/eye-off.png';
import logoutIcon from '../../../assets/images/eye-off.png'; 
import { useNavigate } from 'react-router-dom';
import Container from '../../container';
import { fetchUserdetail } from '../../../api/api';
const Profile = () => {
    const [name, setname] = useState("User");
    const [eamil, setEamil] = useState("abdf@gmail.com");
    const [phone, setphone] = useState("1234567890");
    const [referral, setReferral] = useState(null);
    const [reaferralCount, setReaferralCount] = useState(0);
    
    
    
    
    useEffect (( )=>{
        handlefetchUserdetail()
    },[])

    const handleRefernearn = () => {
      if (referral) {
        navigate(`/referandearn?referralId=${referral}&referralCount=${reaferralCount}`);

      } else {
        navigate('/referandearn');
      }
    };
    const handlefetchUserdetail = async () => {
        try {
          const data = await fetchUserdetail();
          console.log(data, "user API response");
          setname(data?.data?.first_name + " " + data?.data?.last_name )
          setEamil(data?.data?.email )
          setphone(data?.data?.phone_number )
          setReferral(data?.data?.referral_id)
          {data?.data?.referral_count == null ?
            setReaferralCount(0):
            setReaferralCount(data?.data?.referral_count)
          }
          
        } catch (error) {
          console.error('Error fetching partner data:', error);
        }
      };


        const navigate = useNavigate();
      
        const handleLogout = () => {
          localStorage.clear();
          navigate('/');
        };
    return (
        <Container>
        <div className="profile-container">
            <div className="flex-row mt-10">
                <Header />
            </div>
            <img src={profileImage} alt="Profile" className="profile-image" />
            <h2 className="profile-name">{name}</h2>
            <p className="profile-email">{eamil}</p>
            <p className="profile-phone">+91 {phone}</p>
            <p className="profile-phone"> your refer id is : {referral}</p>
            <p className="profile-phone"> your refer count is: {reaferralCount}</p>
            
            <div className="profile-options">
                <div className="profile-option">
                    <img src={editProfileIcon} alt="Edit Profile" className="option-icon" />
                    <p className="option-text">Edit Profile</p>
                </div>
                <div onClick={handleRefernearn} className="profile-option">
                    <img src={referIcon} alt="Refer And Earn" className="option-icon" />
                    <p className="option-text">Refer And Earn</p>
                </div>
                <div className="profile-option">
                    <img src={withdrawIcon} alt="Withdraw Earnings" className="option-icon" />
                    <p className="option-text">Withdraw Earnings</p>
                </div>
                <div className="profile-option">
                    <img src={helpIcon} alt="Help Center" className="option-icon" />
                    <p className="option-text">Help Center</p>
                </div>
                <div onClick={handleLogout} className="profile-option">
                    <img src={logoutIcon} alt="Log out" className="option-icon" />
                    <p className="option-text">Log out</p>
                </div>
            </div>
            <button className="go-back-button">Go Back</button>
        </div>
        </Container>
    );
};

export default Profile;
