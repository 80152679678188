import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import referralImage from '../../../assets/images/referandearn.png'; 
import whatsappIcon from '../../../assets/images/userimg.png'; 
import instagramIcon from '../../../assets/images/userimg.png'; 
import telegramIcon from '../../../assets/images/userimg.png'; 
import shareIcon from '../../../assets/images/userimg.png'; 
import Container from '../../container';

const ReferAndEarn = () => {
    const location = useLocation();
    const [referralId, setReferralId] = useState('');
    const [referralCount, setReferralCount] = useState(0);
    const [copySuccess, setCopySuccess] = useState('');
    const [AmountWid, setAmountWid] = useState(0);
    

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralId || 'AbCdEfGhIjK');
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }

        // Clear the success message after a few seconds
        setTimeout(() => {
            setCopySuccess('');
        }, 2000);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setAmountWid(params.get('points-earned'))
        setReferralId(params.get('referralId'));
        setReferralCount(params.get('referralCount'));
    }, [location]);

    return (
        <Container>
            <div className="refer-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <div className="refer-status">
  {referralCount <= 2  ? (
    <>
      To withdraw {AmountWid}, you need to refer 3 friends <span className="refer-count">{referralCount ?? 0}/3</span>
    </>
  ) : (
    <>Congratulations! Your refer count is {referralCount}, eligible for {AmountWid} withdrawal.</>
  )}
</div>
                <h2 className="refer-title">Refer & Earn</h2>
                <img src={referralImage} alt="Refer and Earn" className="referral-image" />
                <div className="refer-steps">
                    <div className="step">
                        <span className="step-number">01</span>
                        Refer your friends.
                    </div>
                    <div className="step">
                        <span className="step-number">02</span>
                        Let them login with your referral code.
                    </div>
                    <div className="step">
                        <span className="step-number">03</span>
                        Get {AmountWid} instantly.
                    </div>
                </div>
                <div className="referral-code-container">
                    <label className="referral-code-label">Your Referral Code</label>
                    <div className="referral-code-box">
            <span className="referral-code">{referralId || 'AbCdEfGhIjK'}</span>
            <button className="copy-button" onClick={copyToClipboard}>
                
                {copySuccess ? <span className="copy-success">{copySuccess}</span> :<span>Tap to copy</span>}
            </button>
            
        </div>
                </div>
                <div className="social-invite-container">
                    <h3 className="social-invite-title">Invite by Social Media</h3>
                    <div className="social-icons">
                        <img src={whatsappIcon} alt="WhatsApp" className="social-icon" />
                        <img src={instagramIcon} alt="Instagram" className="social-icon" />
                        <img src={telegramIcon} alt="Telegram" className="social-icon" />
                        <img src={shareIcon} alt="Share" className="social-icon" />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ReferAndEarn;
