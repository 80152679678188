import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import watchVideosIcon from '../../../assets/images/player.png'; 
import referFriendsIcon from '../../../assets/images/earnmoney.png'; 
import getPaidIcon from '../../../assets/images/earned.png'; 

const HowToEarn = () => {
    return (
        <div className="how-to-earn-container">
            <div className='flex-row mt-10'>
                <Header />
            </div>
            <h2 className="how-to-earn-title">How To Earn</h2>
            <div className="earn-step">
                <img src={watchVideosIcon} alt="Watch Videos" className="earn-icon" />
                <h3 className="earn-step-title">Watch Videos</h3>
                <p className="earn-step-description">
                    Commercial Ads, Movies, And TV Shows Online. It’s The Perfect Way To Earn Money.
                </p>
            </div>
            <div className="earn-step">
                <img src={referFriendsIcon} alt="Refer Your Friends" className="earn-icon" />
                <h3 className="earn-step-title">Refer Your Friends</h3>
                <p className="earn-step-description">
                    We’ll Confirm As Soon As Your Friend Joins. You Get ₹50 And Your Friend Gets ₹250.
                </p>
            </div>
            <div className="earn-step">
                <img src={getPaidIcon} alt="Get Paid" className="earn-icon" />
                <h3 className="earn-step-title">Get Paid</h3>
                <p className="earn-step-description">
                    You Can Transfer It To Your Paytm, PhonePe, And More Options.
                </p>
            </div>
            <button className="next-button">Next</button>
        </div>
    );
};

export default HowToEarn;
