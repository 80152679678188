import React from 'react';
import { useNavigate } from 'react-router-dom';
import backicon from '../../../assets/images/backicon.png';
import paisebnaotext from '../../../assets/images/paisebnaotext.png'
import '../Login/LoginScreen.css';

const Header = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
        <img
            src={backicon}
            alt="back icon"
            onClick={goBack}
            className="back-button headbackbtn"
        />
        <img src={paisebnaotext} alt="paisebanotextlogo" className="smalltextlogo " />
        </>
    );
};

export default Header;
