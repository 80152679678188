import React, { useState, useEffect,useCallback } from 'react';
import Slider from 'react-slick';
import '../Login/LoginScreen.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from '../common/header';
import userIcon from '../../../assets/images/user.png';
import referIcon from '../../../assets/images/rupee.png';
import depositIcon from '../../../assets/images/deposit.png';
import viewsIcon from '../../../assets/images/supereye.png';
import partnerImage from '../../../assets/images/partner.png';
import errorIcon from '../../../assets/images/error.png';
import { useNavigate } from 'react-router-dom';
import { dashboardApi, fetchPartnerData, addReward, fetchUserdetail } from '../../../api/api';
import ReactPlayer from 'react-player';
import paisebnaotext from '../../../assets/images/paisebnaotext.png'
import Container from '../../container';
import MemberShipPlan from "./membership.js";
import tresery from "../../../assets/images/chestmoney.png"
import earncash from '../../../assets/images/newCash.png';
import earcchest from '../../../assets/images/earnChest.png';

const Dashboard = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [dashboardData, setDashboardData] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [pointsEarned, setPointsEarned] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [withdrawalPopupVisible, setWithdrawalPopupVisible] = useState(false);
    const [name, setname] = useState("User");
    const [totalViews, setTotalViews] = useState(10);
    const [renderUi, setRenderUi] = useState(false);
    const [videoPlayUrl, setVideoPlayUrl] = useState(null);
    const [rewardAdded, setRewardAdded] = useState(false);
    const [videoCompleted, setVideoCompleted] = useState(false);
    const [referralId, setReferralId] = useState(null);
    const [referralCount, setreferralCount] = useState(0);
    
    
    
    

   const token= localStorage.getItem('access_token');    
    const navigate = useNavigate();

    
    const fetchDashboardData = useCallback(async () => {
      try {
          const response = await dashboardApi();
          console.log(response.data, "Dashboard API response");

          if (response.data && Array.isArray(response.data)) {
              setDashboardData(response.data);
              setRenderUi(true);
          } else {
              console.error('Invalid data format received from dashboard API');
          }
      } catch (error) {
          console.error('Error fetching dashboard data:', error);
      }
  }, [dashboardApi]);

    
      const handlefetchPartnersData = async () => {
        try {
          const data = await fetchPartnerData();
          console.log(data, "Partner API response");
          setDashboardData(data);
        } catch (error) {
          console.error('Error fetching partner data:', error);
        }
      };
    

      const handlefetchUserdetail = async () => {
        try {
          const data = await fetchUserdetail();
          console.log(data, "user API response");
          setreferralCount(data?.data?.referral_count)
          setReferralId(data?.data?.referral_id)
          setname(data?.data?.first_name)
          setPointsEarned(data?.data?.points)
          setTotalViews(data?.data?.views)
        } catch (error) {
          console.error('Error fetching partner data:', error);
        }
      };

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleRedirect = () => {
        navigate('/profile');
    };

    const handleRefernearn = () => {
        navigate(`/referandearn?points-earned=${formatCurrency(pointsEarned)}&referralId=${referralId}&referralCount=${referralCount}`);

    };

    const handleDeposit = () => {
        navigate('/platform-fee');
    };

    const handleAmountWithdrawal = () => {
        if (pointsEarned < 500) {
            setWithdrawalPopupVisible(true);
        } else {
            navigate(`/amount-withdraw?balance=${pointsEarned}`);
        }
    };

    const handleRetry = () => {
        alert('Retrying payment...');
        setIsVisible(false);
    };

   

    useEffect(() => {
        fetchDashboardData();
        handlefetchPartnersData();
        handlefetchUserdetail();
      }, [fetchDashboardData]);
    
     
     
      const handlePlayButtonClick = () => {
        if(currentVideoIndex < dashboardData?.length -1){
        setIsPlaying(!isPlaying); }
      };
   
      useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    useEffect(() => {
        const checkDataAndRefresh = () => {
            if (dashboardData.length > 0) {
                if (currentVideoIndex < dashboardData.length) {
                    const currentLink = dashboardData[currentVideoIndex]?.redirection_link;
                    if (!currentLink) {
                        console.error('Invalid video link, refreshing the page');
                        window.location.reload(); // Refresh the page if the link is invalid
                    } else {
                        setIsPlaying(true);
                        setRewardAdded(false);
                        setVideoCompleted(false);
                    }
                }
            } else {
                console.error('Dashboard data is empty, refreshing the page');
                window.location.reload(); // Refresh the page if the data is empty
            }
        };

        // Check data after a short delay to avoid continuous refreshing
        const delay = setTimeout(checkDataAndRefresh, 2000); // 2 seconds delay

        // Cleanup timeout on component unmount
        return () => clearTimeout(delay);
    }, [dashboardData, currentVideoIndex ]);


    
    useEffect(() => {
      // Reset videoCompleted and rewardAdded states when the currentVideoIndex changes
      setVideoCompleted(false);
      setRewardAdded(false);
  }, [currentVideoIndex]);

  const handleAddReward = async (id) => {

    try {
      const data = await addReward(id);
      console.log(data, "add reward");
      setRewardAdded(true); 
    } catch (error) {
      console.error('Error adding reward:', error);
    }
  };
  const handleProgress = (state) => {
    const videoDuration = dashboardData[currentVideoIndex]?.timer || 0;
    const playedRatio = state.playedSeconds / videoDuration;
    setPlayed(playedRatio);
    setCurrentTime(state.playedSeconds);

    if (state.playedSeconds >= videoDuration && !videoCompleted) {
        setIsPlaying(false);
        setVideoCompleted(true);

        // Step 1: Display the popup after a delay
        setTimeout(() => {
            setPopupVisible(true);

            // Step 2: Add the reward if not added already
            if (!rewardAdded) {
                handleAddReward(dashboardData[currentVideoIndex]?.id);
                setRewardAdded(true);
            }

            // Step 3: Hide the popup and move to the next video after another delay
            setTimeout(() => {
                setPopupVisible(false);
                handleNextVideo();
            }, 3000); // Adjust the timeout duration as needed
        }, 1000); // Initial delay before showing the popup
    }
};
const handleNextVideo = () => {
  handlefetchUserdetail();
  if (currentVideoIndex < dashboardData.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
      setIsPlaying(true);
  } else {
      // If it was the last video, do nothing or handle end of playlist logic
      setIsPlaying(false);
  }
};
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-IN', { 
      style: 'currency', 
      currency: 'INR',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0  
  }).format(amount);
};
  
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false 
    };

    return (
        <Container>
        <div className="dashboard-container">
            {isVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <button className="close-button" onClick={handleClose}>×</button>
                        </div>
                        <div className="popup-content">
                            <img src={errorIcon} alt="error" className="error-icon" />
                            <h2 className="popup-title">Payment Failed</h2>
                            <button className="retry-button" onClick={handleRetry}>Retry</button>
                        </div>
                    </div>
                </div>
            )}

          
{popupVisible && (
  <div className="popup-overlay">
    <div className="popup-container">
      <div className="popup-header">
        <button className="close-button" onClick={() => setPopupVisible(false)}>×</button>
      </div>
      <div className="popup-content">
        <img width={200} src={tresery} alt="Congratulations" className="popup-image" />
        <h2 className="popup-title">Congratulations!!</h2>
        <p className="popup-message">You Earned ₹{dashboardData[currentVideoIndex]?.points}</p>
      </div>
    </div>
  </div>
)}


            {withdrawalPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <button className="close-button" onClick={() => setWithdrawalPopupVisible(false)}>×</button>
                        </div>
                        <div className="popup-content">
                            <h2 className="popup-title">Minimum Withdrawal Amount</h2>
                            <p>You need to have at least ₹500 to withdraw.</p>
                        </div>
                    </div>
                </div>
            )}

            

            
                <div className="top-buttons ">
                    <button onClick={handleRedirect} className="top-button user">
                        <img src={userIcon} alt="User" className="icon" /> Hi, {name}
                    </button>
                    <button onClick={handleRefernearn} className="top-button refer-earn">
                        <img src={referIcon} alt="Refer & Earn" className="icon" /> Refer & Earn
                    </button>
                    <button onClick={handleDeposit} className="top-button deposit">
                        <img src={depositIcon} alt="Deposit" className="icon" /> Deposit
                    </button>
                </div>

                

            {renderUi ? (
                <>
                    <div className="account-info">
                        <div className='flex-row'> 
                            <span className='f-400'>welcome to </span>
                            <img src={paisebnaotext} alt="paisebanotextlogo" className="smalltextlogoone ml-7 mr-2" />
                            <span className='f-400'> <span className='bold' >App</span> watch & Earn</span>
                        </div>
                        <div className='flex-row'> 
                            <div className="views-info view-bg">
                                <img src={viewsIcon} alt="Views" className="views-icon" />
                                <span className='mt-10'>{totalViews} Views</span>
                            </div>
                            <div className="balance-info">
                                <div className="balance-amount flex-col">
                                    <span style={{color:"white"}} >{formatCurrency(pointsEarned)}</span>
                                    <span className='f12-white'>Account Balance</span>
                                </div>
                                <button onClick={handleAmountWithdrawal} className="withdraw-button">Withdraw</button>
                            </div>
                        </div>
                    </div>

                    <MemberShipPlan />
                    <div className="video-section">
      <h3 className="video-title">View this video and get ₹{dashboardData[currentVideoIndex]?.points}</h3>
      <div className="video-player">
       

      <ReactPlayer
        url={dashboardData[currentVideoIndex]?.redirection_link}
        playing={isPlaying}
        controls={false}
        playbackRate={1}
        onProgress={handleProgress}
        width="100%"
        height="100%"
      />
         
        <button onClick={handlePlayButtonClick} className="play-button ">
          Play
        </button>
      
      </div>
     

      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="progress" style={{ width: `${played * 100}%` }}></div>
        </div>
        <img className="progress-image" width={43} src={earcchest} />
      </div>
     <div style={{display: "flex"}} className='flexrownew'>
     <div className="video-details">
        <p>Watch full video and earn</p>
        <img width={21} height={21} src={earncash}/>
      </div>
      <div className="video-timer">
        <p><span className='red'>{formatTime(currentTime)}</span> / {formatTime(dashboardData[currentVideoIndex]?.timer)}</p>
      </div>
     </div>
    </div>
                </>
            ) : (
                <p>Loading...</p>
            )}

<div className="partners-section">
    <h3 className="partners-title">Our Partners</h3>
    <div className="partners-slider">
        <Slider {...sliderSettings}>
        <div>
    <a href="https://yarsi.in" target="_blank" rel="noopener noreferrer">
        <img src={partnerImage} alt="Partner 1" className="partner-image" />
    </a>
</div>
<div>
    <a href="https://yarsi.in" target="_blank" rel="noopener noreferrer">
        <img src={partnerImage} alt="Partner 2" className="partner-image" />
    </a>
</div>
<div>
    <a href="https://yarsi.in" target="_blank" rel="noopener noreferrer">
        <img src={partnerImage} alt="Partner 3" className="partner-image" />
    </a>
</div>
<div>
    <a href="https://yarsi.in" target="_blank" rel="noopener noreferrer">
        <img src={partnerImage} alt="Partner 4" className="partner-image" />
    </a>
</div>
        </Slider>
    </div>
</div>
        </div>
        </Container>
    );
};

export default Dashboard;
