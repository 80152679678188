// src/components/Container.js
import React from 'react';
import '../styles/mandate.css';

const Container = ({ children }) => {
  return <div className="container">{children}</div>;
  
};

export default Container;
