import React, { useState, useEffect } from 'react';
import '../Login/LoginScreen.css';
import { handleGetMembership } from '../../../api/api';

const MembershipPlans = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedDeposit, setSelectedDeposit] = useState(null);
    const [newplans, setPlans] = useState([]);
    const [withdrawalDays, setWithdrawalDays] = useState(8);
    

    const plans = [
        {
            type: 'Normal',
            note: 'Withdrawal in 7 days',
            details: ['Without Membership get ₹1 per video.'],
            deposits: []
        },
        {
            type: 'Standard',
            note: 'Withdrawal in 3 days',
            details: [
                'Deposit ₹19 and get ₹2 per video.',
                'Deposit ₹49 and get ₹5 per video.',
                'Deposit ₹99 and get ₹10 per video.'
            ],
            deposits: [
                { amount: 19, perVideo: 2 },
                { amount: 49, perVideo: 5 },
                { amount: 99, perVideo: 10 }
            ]
        },
        {
            type: 'VIP',
            note: 'Withdrawal in 1 day',
            details: [
                'Deposit ₹249 and get ₹35 per video.',
                'Deposit ₹499 and get ₹80 per video.'
            ],
            deposits: [
                { amount: 249, perVideo: 35 },
                { amount: 499, perVideo: 80 }
            ]
        }
    ];

    useEffect(() => {
        const fetchMembershipData = async () => {
            try {
                const data = await handleGetMembership();
                console.log(data, "membership data");
                setWithdrawalDays(data[0]?.withdrawl_days)
                // alert(data.data[0].withdrawl_days)
                setPlans(data.plans);
            } catch (error) {
                console.error('Error fetching membership data:', error);
            }
        };

        fetchMembershipData();
    }, []);

    const handleDepositClick = (plan, deposit) => {
        setSelectedPlan(plan);
        setSelectedDeposit(deposit);
        setIsPopupVisible(true);
    };

    

    const handleConfirm = () => {
        setIsPopupVisible(false);
        // Redirect to platform-fee page after confirming the payment
        window.location.href = `/platform-fee?plan=${selectedPlan.type}&amount=${selectedDeposit.amount}&perVideo=${selectedDeposit.perVideo}`;
    };

    return (
        <div className="membership-container">
           <div className='flexcenterf mb-20'>
           <h3 className="plans-title">Membership Plans for You</h3>
             <div className="gradient-background">
            </div>

           </div>
            {plans.map((plan, index) => (
                <div key={index} className="plan">
                    <h4>
                        {plan.type} <span className="plan-note red">Withdrawal in {withdrawalDays} days</span>
                    </h4>
                    {plan.details.map((detail, index) => (
                        <div className="plan-detail displayfix" key={index}>
                            {plan.type == "Normal" ?
                            <>
                             <span  className="bullet">►  </span> 
                            {detail}
                            </>
                            : ""}
                        </div>
                    ))}
                    {plan.deposits.length > 0 && (
                        <div className="deposits">
                            {plan.deposits.map((deposit, index) => (
                                <div key={index} className="deposit-item">
                                    <span className="bullet">►</span>
                                    <span>Deposit <span className='green font60014'>₹{deposit.amount} </span>and get <span className='green font60014'> ₹{deposit.perVideo} </span>per video.</span>
                                    <div 
                                        className="deposit-button" 
                                        onClick={() => handleDepositClick(plan, deposit)}
                                    >
                                        Deposit
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}

            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <h2>Confirm Payment</h2>
                        <p>Are you sure you want to proceed with the payment for {selectedPlan?.type} plan of ₹{selectedDeposit?.amount}?</p>
                        <button onClick={handleConfirm} className="confirm-button">Yes</button>
                        <button onClick={() => setIsPopupVisible(false)} className="cancel-button">No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MembershipPlans;
