import React, { useState } from 'react';
import './LoginScreen.css';
import paisebnaologo from '../../../assets/images/paisebnaologo.png';
import googlebtn from '../../../assets/images/googlebtn.png';
import emailicon from '../../../assets/images/email.png';
import lock from '../../../assets/images/lock.png';
import eyeoff from '../../../assets/images/eye-off.png';
import eyeon from '../../../assets/images/eye-on.png';
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../../api/api'; 
import Container from '../../container';
import GoogleAuth from '../../pages/GoogleAuth';

const LoginScreen = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loginError, setLoginError] = useState('');
  
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
  
    if (value.length > 154) {
      setEmailError('Email must be 154 characters or less.');
    } else {
      setEmail(value);
  
      if (!validateEmail(value)) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
    }
  };

 


  const toggleVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await loginUser(email, password);
      console.log(response, "response");
      alert(response?.access_token)
      localStorage.setItem('access_token', response?.access_token);
      if (localStorage.getItem('access_token') === null || localStorage.getItem('access_token') === undefined || localStorage.getItem('access_token') === '') {
        
        throw new Error('Access token is not defined');
        return;
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('Invalid credentials. Please try again.');
    }
  };

  return (
    <Container>
    <div className="">
      <div className='flex-row mt-10'>
        <Header />
      </div>
      <img src={paisebnaologo} alt="Logo" className="logo mt-20" />

      <h2 className="subtitle">Log In</h2>
      <div className="input-container">
        <form onSubmit={handleLogin}>
          <div className="input-wrapper mb-20">
            <img src={emailicon} alt="Email Icon" className="icon-size icon-inside-input lock-icon" />
            <input
              type="email"
              placeholder="Enter your email"
              className="input-field"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          {emailError && <div className="error-message">{emailError}</div>}

          <div className="input-wrapper">
            <img src={lock} alt="Password Icon" className="icon-size icon-inside-input " />
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter your password"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <img
              src={passwordVisible ? eyeon : eyeoff}
              alt="Toggle Visibility"
              className="visibility-icon icon-size icon-inside-input"
              onClick={toggleVisibility}
            />
          </div>

          {loginError && <div className="error-message">{loginError}</div>}

          <p className="terms-text fontstyle">
            By going forward you agree to <a href="/termsandcondition" className="red-link underline">Terms & Conditions</a>
          </p>
          <a href="/enter-email" className="fontstyle bolder red-link">Forgot Password?</a>
          <button type="submit" className="login-button-login bolder">Login</button>
        </form>
      </div>

      <div className='hrline'>
        <p className="or-text ">or login with</p>
        <hr />
      </div>
      <GoogleAuth />
      {/* <img src={googlebtn} alt="Logo" className="googlebtn" /> */}
    </div>
    </Container>
  );
};

export default LoginScreen;
