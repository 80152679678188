import React, { useState } from 'react';
import LoginScreen from './pages/Login/LoginScreen';
import Container from './container';
import SignupScreen from './pages/signup/SignupScreen';

const Home = () => {
    const [userType, setUserType] = useState("old");

    return (
        <Container>        <div>
            {userType === "old" ?  <SignupScreen />: <LoginScreen /> }
        </div>
        </Container>
    );
};

export default Home;
