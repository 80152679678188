import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import Container from '../../container';
const WithdrawAmount = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialBalance = queryParams.get('balance') || 0;

    const [amount, setAmount] = useState(initialBalance); 
    const [balance, setBalance] = useState(initialBalance);
    const [upiId, setUpiId] = useState('');
    const [isValid, setIsValid] = useState(true);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', { 
            style: 'currency', 
            currency: 'INR',
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0  
        }).format(amount);
      };

      const validateUpiId = (id) => {
        const upiPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/;
        return upiPattern.test(id);
    };
    const handleUPInputChange = (e) => {
        const { value } = e.target;
        setUpiId(value);
        setIsValid(validateUpiId(value));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValid) {
            alert('UPI ID is valid!');
        } else {
            alert('Please enter a valid UPI ID.');
        }
    };

    useEffect(() => {
        setBalance(initialBalance);
    }, [initialBalance]);

    const handleSliderChange = (e) => {
        setAmount(e.target.value);
    };

    return (
        <Container>
            <div className="withdraw-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <h2 className="balance-title">Available Balance</h2>
                <p style={{color: "green"}} className="balance-amount">{formatCurrency(balance)}</p>
                <div className="withdraw-section">
                    <h3 className="withdraw-instruction">Select amount you want to withdraw</h3>
                    <div className="withdraw-amount-box">
                        {formatCurrency(amount?.toLocaleString())}
                    </div>
                    <input 
                        type="range" 
                        min="0" 
                        max={balance} 
                        value={amount} 
                        className="withdraw-slider" 
                        onChange={handleSliderChange}
                    />
                    <div className="slider-labels">
                        <span>₹0</span>
                        <span>{formatCurrency(balance)}</span>
                    </div>
                </div>
                <div className="upi-section">
                    <h3 className="upi-title">Enter your UPI ID</h3>
                    <form onSubmit={handleSubmit}>
            <input 
                type="text" 
                placeholder="Your UPI ID" 
                className={`upi-input ${isValid ? '' : 'invalid'}`}
                value={upiId}
                maxLength={50} 
                onChange={handleUPInputChange}
            />
            {!isValid && <p className="error-message">Invalid UPI ID format.</p>}
        </form>
                    <p className="upi-instruction">Enter Your UPI ID to Receive Amount</p>
                </div>
            </div>
            <button onClick={handleSubmit} className="verify-button">Withdraw Amount</button>
            <p className="safety-note">100% Safe & Secure</p>
        </Container>
    );
};

export default WithdrawAmount;
