import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import rupeeIcon from '../../../assets/images/rupee.png'; 
import PlatformFeesicon from '../../../assets/images/platformimg.png'; 
import Container from '../../container';

const PlatformFees = () => {
    return (
        <Container>
        <div className="platform-fees-container">
            <div className="flex-row mt-10">
                <Header />
            </div>
            <h2 className="verify-titlee">Platform Fees</h2>
            <div className="instructions-container">
                <p className="verify-instructions">
                    • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                </p>
                <p className="verify-instructions">
                    • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                </p>
            </div>
            <div className="payment-container">
                <div className="payment-methods">
                    <img src={PlatformFeesicon} alt="platformfee" className="payment-icon" />
                   
                </div>
            </div>
            
        </div>
        <button className="verify-button">Pay ₹299</button>
        </Container>
    );
};

export default PlatformFees;
