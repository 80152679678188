import React, { useState } from 'react';
import '../Login/LoginScreen.css';
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import emailicon from '../../../assets/images/email.png';
import Container from '../../container';

const VerifyAccount = () => {
  const [email, setEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    if (newEmail.length > 154) {
      setValidationMessage('Email must be 154 characters or less.');
    } else {
      setEmail(newEmail);
      validateEmail(newEmail);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setValidationMessage('Please enter a valid email address.');
    } else {
      setValidationMessage('');
    }
  };

  const navigate = useNavigate();

  const handleNavigation = () => {
    if (!validationMessage && email) {
      navigate('/enter-email-otp');
    } else {
      validateEmail(email);
    }
  };

  return (
    <Container>
      <div className="verify-account-container">
        <div className='flex-row mt-10'>
          <Header />
        </div>
        
        <h2 className="verify-title">Forgot Password</h2>
        <p className="verify-instructions">
          No worries! Enter your email address below and we will send you a code to reset password
        </p>
        <div className="input-container">
          <img src={emailicon} alt="Lock Icon" className="icon-size" />
          <input
            type="text"
            placeholder="Enter your email"
            className="input-field"
            value={email}
            onChange={handleEmailChange}
            onBlur={() => validateEmail(email)}
          />
          
        </div>

        {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
        <button onClick={handleNavigation} className="verify-button">
          Send Verification Code
        </button>
      </div>
    </Container>
  );
};

export default VerifyAccount;
