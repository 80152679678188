import React from 'react';
import '../Login/LoginScreen.css'; 
import Header from '../common/header';
import errorIcon from '../../../assets/images/error.png'; 
import Container from '../../container';

const OopsScreen = () => {
    const handleWatchVideo= ()=>{
window.location.href="/dashboard"
    }
    return (
        <Container>
            <div className="oops-container">
                <div className="flex-row mt-10">
                    <Header />
                </div>
                <div className="error-icon-container mt-50">
                    <img src={errorIcon} alt="error" className="error-icon" />
                </div>
                <h2 className="oops-title">Oops!!</h2>
                <p className="oops-subtitle">Your Mistake</p>
                <div className="instructions-container">
                    <p className="oops-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p>
                    <p className="oops-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p>
                    <p className="oops-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p>
                    {/* <p className="oops-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p>
                    <p className="oops-instructions">
                        • No Worries! Enter Your Email Address Below And We Will Send You A Code To Reset Password
                    </p> */}
                </div>
               
            </div>
            <button onClick={handleWatchVideo} className="verify-button">Continue Watching Videos</button>
        </Container>
    );
};

export default OopsScreen;
