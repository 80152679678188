import React, { useState } from 'react';
import '../Login/LoginScreen.css';  
import Header from '../common/header';
import lock from '../../../assets/images/lock.png';
import eyeoff from '../../../assets/images/eye-off.png';
import eyeon from '../../../assets/images/eye-on.png';
import { useNavigate } from 'react-router-dom';

const CreateNewPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setError('Password must contain at least 8 characters');
        } else if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
            // Add password reset logic here
            console.log('Password reset successful');
            navigate('/loginn');
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <div className="verify-account-container">
            <div className='flex-row mt-10'>
                <Header />
            </div>
            <h2 className="verify-title">Create New Password</h2>
            <p className="verify-instructions">
                Please Enter And Confirm Your New Password
            </p>
            <div className="input-container">
                <img src={lock} alt="Lock Icon" className="icon-size" />
                <input 
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter your password" 
                    className="input-field" 
                    value={password} 
                    onChange={handlePasswordChange} 
                />
                <img
                    src={passwordVisible ? eyeon : eyeoff}
                    alt="Toggle Visibility"
                    className="visibility-icon icon-size"
                    onClick={togglePasswordVisibility}
                />
            </div>
            <p className="verify-instructions passmustcontainchar">Must contain 8 characters</p>
            <div className="input-container">
                <img src={lock} alt="Lock Icon" className="icon-size" />
                <input 
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder="Confirm your password" 
                    className="input-field" 
                    value={confirmPassword} 
                    onChange={handleConfirmPasswordChange} 
                />
                <img
                    src={confirmPasswordVisible ? eyeon : eyeoff}
                    alt="Toggle Visibility"
                    className="visibility-icon icon-size"
                    onClick={toggleConfirmPasswordVisibility}
                />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button className="verify-button" onClick={handleSubmit}>Reset Password</button>
        </div>
    );
};

export default CreateNewPassword;
