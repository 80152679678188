import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import Home from '../components/Home';
import Login from '../components/Login';
import SignupScreen from '../components/pages/signup/SignupScreen'
import LoginScreen from '../components/pages/Login/LoginScreen'
import ForgetPassword from '../components/pages/Forgetpass/forget-password'
import EnterEmail from '../components/pages/Forgetpass/enter-email'
import UpdatePassword from '../components/pages/Forgetpass/update-password'
import HowToEarn from '../components/pages/howtoearn/how-to-earn'
import Dashboard from '../components/pages/dashboard/dashboard'
import PlatformFees from '../components/pages/termsandcondition/platformfee'
import Terms from '../components/pages/termsandcondition/terms'
import WithdrawAmount from '../components/pages/withdrawal/withdrawamount';
import Profile from '../components/pages/profile/profile.js';
import ReferandEarn from '../components/pages/referandearn/referandearn';
import Registration from '../components/pages/register/register';
import Index from '../components/pages/index';
import Container from '../components/container.js';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        
      <Route
          path="/"
          element={
            <PublicRoute>
              <Index />
            </PublicRoute>
          }
        />
        {/* <Container> */}
        <Route
          path="/home"
          element={
            <PublicRoute>
              <Home />
            </PublicRoute>
          }
        />
        {/* </Container> */}
        
        <Route
          path="/loginold"
          element={
            <PublicRoute restricted={true}>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute restricted={true}>
              <SignupScreen />
            </PublicRoute>
          }
        />
        <Route
          path="/loginn"
          element={
            <PublicRoute restricted={true}>
              <LoginScreen />
            </PublicRoute>
          }
        />
        <Route
          path="/enter-email-otp"
          element={
            <PublicRoute restricted={true}>
              <ForgetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/enter-email"
          element={
            <PublicRoute restricted={true}>
              <EnterEmail />
            </PublicRoute>
          }
        />
        <Route
          path="/update-password"
          element={
            <PublicRoute restricted={true}>
              <UpdatePassword />
            </PublicRoute>
          }
        />
        <Route
          path="/how-to-earn"
          element={
            <PrivateRoute>
              <HowToEarn />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/platform-fee"
          element={
            <PrivateRoute>
              <PlatformFees />
            </PrivateRoute>
          }
        />
        <Route
          path="/termsandcondition"
          element={
            <PublicRoute restricted={true}>
              <Terms />
            </PublicRoute>
          }
        />
        <Route
          path="/amount-withdraw"
          element={
            <PrivateRoute>
              <WithdrawAmount />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/referandearn"
          element={
            <PrivateRoute>
              <ReferandEarn />
            </PrivateRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute restricted={true}>
              <Registration />
            </PublicRoute>
          }
        />
      </Routes>
      
    </Router>
    
  );
};

export default AppRoutes;